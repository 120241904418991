import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import useViewport from "../hooks/useViewport"
import device, { sizes } from "../device"
import { ReserveNow, LearnMore } from "../hooks/useLinks"
import GreenHeading from "../heading"
import { UpperBg, LowerBg } from "./reasons-image"
import NoResults, { PlaceholderImage } from "../noResults"
import ReasonsMobile from "./reasons-mobile"

const Wrapper = styled.div`
  position: relative;
  background: var(--darkgrey);
  ${device.small`padding-top: 2rem;`}
  .bg-first {
    display: block;
    width: 100%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
    ${device.small`padding-bottom: 5rem; display: block;`}
    .image-wrapper {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      position: relative;
      ${device.small`display: none;`}
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
      .bg-lower {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .grid-container-inner {
      margin-top: ${props =>
        props.sectionName === "why-king" ? "-4rem" : "-6rem"};
      position: relative;
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      ${device.large`margin-top: ${props =>
        props.sectionName === "why-king" ? "-8rem" : "-12rem"};`}
      ${device.small`margin-top: ${props =>
        props.sectionName === "why-king" ? "0rem" : "4rem"};`}
    }
  }

  .heading-wrapper {
    display: flex;
    .heading {
      margin: 0 auto;
      font-size: 2.5rem;
      line-height: 0.8;
      ${device.small`font-size: 2rem;`}
      .secondary-heading {
        color: var(--white);
        margin-left: 3rem;
        ${device.small`margin-left: 2rem;`}
      }
    }
  }
  .reasons-wrapper {
    max-width: 1500px;
    width: 90%;
    display: flex;
    margin: 4rem auto 0 auto;
  }
  .grid-wrapper {
    flex: 1;
  }
`

const List = styled.div`
  padding: 2rem 1rem;
  .list-item {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    .cat-img {
      width: 35px;
      filter: var(--white-filter);
    }
    span {
      color: var(--white);
      font-family: "Theano Didot";
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 0.8rem;
      padding: 2px 8px;
      cursor: pointer;
      margin-left: 0.5rem;
    }
    &.selected {
      span {
        color: var(--green);
        border-bottom: 3px solid var(--green);
      }
      .cat-img {
        filter: var(--green-filter);
      }
    }
  }
`
const GridWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .grid-item {
    margin: 1rem;
    flex: 1 1 calc(33.333% - 2rem);
    height: 320px;
    background: var(--white);
    border-radius: 15px;
    overflow: hidden;
    ${device.medium`flex: 1 1 calc(50% - 2rem);`}
    ${device.large`height: 360px;`}
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .title {
      margin: 1rem 0;
      text-transform: none;
      font-size: 1rem;
      ${device.large`font-size: 1.2rem;`}
    }
    .content-inner {
      overflow: hidden;
      text-align: center;
      .image {
        filter: var(--green-filter);
        margin: 0 auto;
        width: 50px;
        ${device.large`width: 64px;`}
      }
    }
    &:hover {
      .content-outer {
        top: 0;
      }
    }
    .content-outer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 100%;
      background: var(--green);
      color: var(--white);
      text-align: center;
      padding: 1.5rem;
      transition: all 0.3s ease-out;
      p {
        font-size: 0.8rem;
      }
      a {
        margin-top: 1rem;
        font-size: 0.7rem;
        letter-spacing: 2px;
        color: var(--gold);
      }
    }
  }
  .not-found {
    position: absolute;
    width: 100%;
    top: 10%;
  }
`

const Links = styled.div`
  margin: 5rem 0 5rem 0;
  display: flex;
  justify-content: center;
  font-size: 0.7rem;
  text-align: center;
  a {
    display: block;
    margin: 0 1rem;
  }
  .learn-more {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: var(--white);
    padding: calc(0.5rem - 1px) 1.5rem;
    border: 1px solid var(--white);
  }
`

const Grid = ({ posts }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
    return () => setLoaded(false)
  }, [posts])

  return (
    <GridWrapper>
      {loaded &&
        posts.map((post, index) => (
          <div className="grid-item" key={index}>
            <div className="content">
              <div className="content-inner">
                {post.featured_media && post.featured_media.localFile ? (
                  <Img
                    className="image"
                    fluid={post.featured_media.localFile.childImageSharp.fluid}
                    alt={post.featured_media.alt_text}
                  />
                ) : (
                  <PlaceholderImage className="image" />
                )}
                <h4 className="title">{post.title.replace("&#038;", "&")}</h4>
              </div>
              <div className="content-outer">
                <h4 className="title">{post.title.replace("&#038;", "&")}</h4>
                <p>{post.acf.text}</p>
                <a href={post.acf.link}>Learn More</a>
              </div>
            </div>
          </div>
        ))}
      {loaded && posts.length === 0 && (
        <div className="not-found">
          <NoResults text="No reasons found" />
        </div>
      )}
    </GridWrapper>
  )
}

const Reasons = ({ sectionName }) => {
  const isMobile = useViewport() <= sizes.small

  const data = useStaticQuery(graphql`
    {
      allWordpressWpReasonsCategories {
        edges {
          node {
            name
            acf {
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 35) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            reasons {
              title
              acf {
                text
                link
              }
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const [selected, setSelected] = useState(0)

  const reasons = data.allWordpressWpReasonsCategories.edges.map(
    ({ node }) => ({
      category: node.name,
      image: node.acf ? node.acf.image : null,
      posts: node.reasons,
    })
  )

  function handleClick(e) {
    const target = e.currentTarget.getAttribute("data-index")
    if (isMobile && selected === target) {
      setSelected(null)
    } else {
      setSelected(target)
    }
  }

  useEffect(() => {
    isMobile ? setSelected(null) : setSelected(0)
  }, [isMobile])

  return (
    <>
      <Wrapper sectionName={sectionName}>
        {sectionName !== "why-king" && <UpperBg />}
        <div className="grid-container">
          <div className="image-wrapper">
            <div className="bg-lower">
              <LowerBg />
            </div>
          </div>
          <div className="grid-container-inner">
            <div className="heading-wrapper">
              <h2 className="heading">
                <GreenHeading text="Reasons" />
                <br />
                <span className="secondary-heading">to choose King</span>
              </h2>
            </div>
            {isMobile || selected === null ? (
              <ReasonsMobile
                reasons={reasons}
                handleClick={handleClick}
                selected={selected}
              />
            ) : (
              <div className="reasons-wrapper">
                <List>
                  {reasons.map((item, index) => (
                    <div
                      className={
                        index === parseInt(selected)
                          ? "list-item selected"
                          : "list-item"
                      }
                      key={index}
                      onClick={handleClick}
                      data-index={index}
                    >
                      {item.image ? (
                        <Img
                          className="cat-img"
                          fluid={item.image.localFile.childImageSharp.fluid}
                          alt={item.image.alt_text}
                        />
                      ) : (
                        <PlaceholderImage className="cat-img" />
                      )}
                      <span>{item.category}</span>
                    </div>
                  ))}
                </List>
                <div className="grid-wrapper">
                  <Grid posts={reasons[selected].posts} />
                  <Links
                    style={{
                      visibility:
                        reasons[selected].posts.length === 0
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <LearnMore className="learn-more" />
                    <ReserveNow />
                  </Links>
                </div>
              </div>
            )}
          </div>
        </div>
        {sectionName === "why-king" && <UpperBg />}
      </Wrapper>
    </>
  )
}

export default Reasons
